import { trackStructEvent } from "@snowplow/browser-tracker";
import { ONEPASS_ACTION_ACCOUNT_MANAGEMENT, ONEPASS_CATEGORY } from "@analytics/consts";

function getLabel(label) {
  switch (label) {
    case "freeTrial":
      return "Start free 30 day trial";

    case "join":
      return "Join OnePass";

    case "manage":
      return "Manage Subscription";

    case "learnMore":
      return "Learn More";

    default:
      return "Start free 30 day trial";
  }
}

export function emitOnePassAccountManagementAction(label) {
  trackStructEvent({
    category: ONEPASS_CATEGORY,
    action: ONEPASS_ACTION_ACCOUNT_MANAGEMENT,
    label: getLabel(label)
  });
}