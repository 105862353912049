import { trackSelfDescribingEvent } from "@snowplow/browser-tracker";

/**
 *
 * Fires event when user completes searchbox interaction
 */
export function emitSearchAction(_ref) {
  var user_text = _ref.user_text,
      is_typehead = _ref.is_typehead,
      final_text = _ref.final_text,
      is_special_search = _ref.is_special_search,
      special_search_details = _ref.special_search_details;
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:au.com.catch/search_action/jsonschema/1-0-1",
      data: {
        user_text: user_text,
        is_typehead: is_typehead,
        final_text: final_text,
        is_special_search: is_special_search,
        special_search_details: special_search_details
      }
    }
  });
}