import { trackStructEvent } from "@snowplow/browser-tracker";
import { ONEPASS_ACTION_MIGRATION_RESULT, ONEPASS_CATEGORY, ONEPASS_MIGRATION_FLASH_MSG_LABEL_ERROR, ONEPASS_MIGRATION_FLASH_MSG_LABEL_SUCCESS } from "@analytics/consts";
export function emitMigrationFlashMessageImpression(_ref) {
  var type = _ref.type,
      message = _ref.message;
  trackStructEvent({
    category: ONEPASS_CATEGORY,
    action: ONEPASS_ACTION_MIGRATION_RESULT,
    label: type === "success" ? ONEPASS_MIGRATION_FLASH_MSG_LABEL_SUCCESS : ONEPASS_MIGRATION_FLASH_MSG_LABEL_ERROR,
    property: message
  });
}