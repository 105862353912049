import { useEffect, useState } from "react";
/**
 * Returns a value from the values array corresponding to the first matching media query in the queries array, or undefined if there are no matches.
 */

export var useMedia = function useMedia(
/** List of media queries */
queries,
/** Values to return given that the corresponding media query matches */
values) {
  var _useState = useState(),
      value = _useState[0],
      setValue = _useState[1];

  useEffect(function () {
    var mediaQueryLists = queries.map(function (q) {
      return window.matchMedia(q);
    });

    var getValue = function getValue() {
      var index = mediaQueryLists.findIndex(function (mql) {
        return mql.matches;
      });
      return values === null || values === void 0 ? void 0 : values[index];
    };

    setValue(getValue);

    var handler = function handler() {
      return setValue(getValue);
    };

    mediaQueryLists.forEach(function (mql) {
      return mql.addListener(handler);
    });
    return function () {
      return mediaQueryLists.forEach(function (mql) {
        return mql.removeListener(handler);
      });
    };
  }, [queries, values]);
  return value;
};