import { differenceInCalendarDays, differenceInHours, differenceInMinutes, differenceInSeconds } from "date-fns";
/**
 * Extension usage of date-fns' differenceIn...
 * parsed in the following order:
 *
 * - seconds
 * - minutes
 * - hours
 * - days
 */

export var parseTimeDifference = function parseTimeDifference(dateLeft, dateRight) {
  try {
    var firstDate = typeof dateLeft === "string" ? new Date(dateLeft) : dateLeft;
    var secondDate = typeof dateRight === "string" ? new Date(dateRight) : dateRight;
    var diffInSeconds = differenceInSeconds(firstDate, secondDate);
    var absDiffInSeconds = Math.abs(diffInSeconds);

    if (absDiffInSeconds < 60) {
      return {
        value: diffInSeconds,
        unit: "s"
      };
    }

    if (absDiffInSeconds < 3600) {
      return {
        value: differenceInMinutes(firstDate, secondDate),
        unit: "m"
      };
    }

    if (absDiffInSeconds < 86400) {
      return {
        value: differenceInHours(firstDate, secondDate),
        unit: "h"
      };
    }

    return {
      value: differenceInCalendarDays(firstDate, secondDate),
      unit: "d"
    };
  } catch (_) {
    return undefined;
  }
};