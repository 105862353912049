/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import Head from "next/head";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var GTM = function GTM() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      enabled = _ref.enabled,
      containerId = _ref.containerId;

  if (!enabled || !containerId) {
    return null;
  }

  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Head, null, ___EmotionJSX("script", {
    dangerouslySetInnerHTML: {
      __html: "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','".concat(containerId, "');")
    }
  })), ___EmotionJSX("noscript", null, ___EmotionJSX("iframe", {
    src: "//www.googletagmanager.com/ns.html?id=".concat(containerId),
    height: "0",
    width: "0",
    style: {
      display: "none",
      visibility: "hidden"
    }
  })));
};