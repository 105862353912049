import { trackSelfDescribingEvent } from "@snowplow/browser-tracker";
export function emitEventImpression(_ref) {
  var event_id = _ref.event_id,
      source_type = _ref.source_type,
      position = _ref.position,
      badge_text = _ref.badge_text,
      event_type = _ref.event_type,
      from_product_displayed_price = _ref.from_product_displayed_price,
      from_product_ids = _ref.from_product_ids,
      from_product_offer_id = _ref.from_product_offer_id,
      schedule_entry_id = _ref.schedule_entry_id,
      subtitle = _ref.subtitle,
      title = _ref.title;
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:au.com.catch/event_impression/jsonschema/1-0-5",
      data: {
        event_id: event_id,
        source_type: source_type,
        position: position,
        badge_text: badge_text,
        event_type: event_type,
        from_product_displayed_price: from_product_displayed_price,
        from_product_ids: from_product_ids,
        from_product_offer_id: from_product_offer_id,
        schedule_entry_id: schedule_entry_id,
        subtitle: subtitle,
        title: title
      }
    }
  });
}