import { trackStructEvent } from "@snowplow/browser-tracker";
import { TACTICAL_CATEGORY_CATEGORY } from "@analytics/consts";
export function emitTacticalCategory(_ref) {
  var label = _ref.label,
      action = _ref.action,
      property = _ref.property;
  trackStructEvent({
    category: TACTICAL_CATEGORY_CATEGORY,
    action: action,
    label: label,
    property: property
  });
}