import { Events } from "../types";
import { submitSPEvents } from "./emitSnowplowEvent";
export function emitDeliveryType(_ref) {
  var availableDeliveryTypes = _ref.availableDeliveryTypes,
      price = _ref.price,
      selected = _ref.selected,
      onepassEligible = _ref.onepassEligible,
      deliveryEstimateLabel = _ref.deliveryEstimateLabel;
  submitSPEvents([{
    context: Events.DELIVERY_TYPE,
    options: {
      availableDeliveryTypes: availableDeliveryTypes,
      price: price,
      selected: selected,
      onepassEligible: onepassEligible,
      deliveryEstimateLabel: deliveryEstimateLabel
    }
  }]);
}