import { trackSelfDescribingEvent } from "@snowplow/browser-tracker";
import { Events } from "../types";
import { submitSPEvents } from "./emitSnowplowEvent";
export function emitAddToCartLegacy(event) {
  event.snowplowContexts.forEach(function (snowplowEvent) {
    return trackSelfDescribingEvent({
      event: snowplowEvent
    });
  });
}
export function emitAddToCart(_ref) {
  var success = _ref.success,
      source_id = _ref.source_id,
      source_type = _ref.source_type,
      source_position = _ref.source_position,
      offer_id = _ref.offer_id,
      position = _ref.position,
      event_id = _ref.event_id,
      trigger = _ref.trigger,
      cart_type = _ref.cart_type,
      customer_id = _ref.customer_id,
      srtrev = _ref.srtrev;
  submitSPEvents([{
    context: Events.ADD_TO_CART,
    options: {
      success: success,
      source_id: source_id,
      source_type: source_type,
      source_position: source_position,
      offer_id: offer_id,
      position: position,
      event_id: event_id || 1,
      trigger: trigger,
      cart_type: cart_type,
      customer_id: customer_id !== null && customer_id !== void 0 ? customer_id : "",
      srtrev: srtrev
    }
  }, {
    context: Events.USER
  }, {
    context: Events.CART
  }]);
}