// Normally we'd send a product interaction event ourselves when the user clicks on a product,
// however that requires us to fetch more context from Snowplow, which means we'd need to get
// in the way of the link. Instead, we add the necessary information to the URL and let the
// recipient fire the events on page load
export function getEffectiveUrl(_ref) {
  var id = _ref.id,
      sourceId = _ref.sourceId,
      sourceType = _ref.sourceType,
      sourcePosition = _ref.sourcePosition,
      offerId = _ref.offerId,
      url = _ref.url;
  var existingParams = getParams(url);
  var required = {
    pid: id,
    sid: sourceId,
    st: sourceType,
    sp: sourcePosition,
    oid: offerId
  };
  var extraParams = Object.keys(required).filter(function (key) {
    return existingParams[key] == null;
  }).map(function (key) {
    return "".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(required[key]));
  }).join("&");
  return "".concat(url).concat(url.includes("?") ? "&" : "?").concat(extraParams);
}

function getParams(url) {
  var queryParts = url.replace(/.*\?/, "").split("&").map(function (part) {
    return part.split("=");
  });
  return Object.fromEntries(queryParts);
}