import { trackStructEvent } from "@snowplow/browser-tracker";
import { ONEPASS_ACTION_MIGRATION_NUDGE, ONEPASS_CATEGORY } from "@analytics/consts";

function getLabel(label) {
  switch (label) {
    case "proceed":
    default:
      return "Show Me How";
  }
}

export function emitMigrationNudgeAction(label) {
  trackStructEvent({
    category: ONEPASS_CATEGORY,
    action: ONEPASS_ACTION_MIGRATION_NUDGE,
    label: getLabel(label)
  });
}