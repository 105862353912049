import { trackStructEvent } from "@snowplow/browser-tracker";
import { ONEPASS_ACTION_MIGRATION_RESULT, ONEPASS_CATEGORY } from "@analytics/consts";

function getLabel(label) {
  switch (label) {
    case "close":
      return "Close";

    case "confirm":
      return "Got it/Ok";

    default:
      return "Got it/Ok";
  }
}

export function emitCancellationModalAction(label) {
  trackStructEvent({
    category: ONEPASS_CATEGORY,
    action: ONEPASS_ACTION_MIGRATION_RESULT,
    label: getLabel(label)
  });
}