import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["lines", "children"];

var _templateObject, _templateObject2;

import React from "react";
import { styled } from "@catchoftheday/theme";
import { Span } from "../Text";
import { jsx as ___EmotionJSX } from "@emotion/react";
var TruncateMultiline = styled(Span)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: -webkit-box;\n  -webkit-line-clamp: ", ";\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n"])), function (props) {
  return props.lines;
});
var TruncateSingleLine = styled(Span)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: block;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n"])));
export var Truncate = function Truncate(_ref) {
  var lines = _ref.lines,
      children = _ref.children,
      spanProps = _objectWithoutProperties(_ref, _excluded);

  return lines ? ___EmotionJSX(TruncateMultiline, _extends({
    lines: lines
  }, spanProps), children) : ___EmotionJSX(TruncateSingleLine, spanProps, children);
};
Truncate.displayName = "Truncate";
export default Truncate;