/**
 * Delivery Estimate Analytics
 */
import { useEffect } from "react";
import { emitDeliveryEstimate } from "../../events";

/**
 *
 * @example ```
 * useDeliveryEstimateAnalytics({
 *   enabled: true,
 *   context: {
 *     ...deliveryEstimate,
 *     postcode: deliveryLocation?.postcode,
 *     deliveryType: selectedShippingOption?.type,
 *     parcelType: dispatchNotice?.type,
 *   },
 * });
 * ```
 */
export function useDeliveryEstimateAnalytics(_ref) {
  var _ref$enabled = _ref.enabled,
      enabled = _ref$enabled === void 0 ? false : _ref$enabled,
      context = _ref.context,
      options = _ref.options;

  var _ref2 = context || {},
      confidence = _ref2.confidence,
      deliveryType = _ref2.deliveryType,
      label = _ref2.label,
      labelType = _ref2.labelType,
      maxRange = _ref2.maxRange,
      minRange = _ref2.minRange,
      parcelType = _ref2.parcelType,
      postcode = _ref2.postcode,
      shortLabel = _ref2.shortLabel,
      timeStamp = _ref2.timeStamp;

  useEffect(function () {
    // Provide some DX by warning the developer if they've enabled analytics but
    // not provided any options.
    // eslint-disable-next-line n/no-process-env
    if (false) {
      if (enabled && !context) {
        console.warn("You've enabled delivery estimate analytics but not provided any context.");
      }
    } // Do nothing if not enabled or missing options.


    if (!enabled || !context) return; // Only emit if all required options are available

    if (!deliveryType || !postcode || !labelType || !label || !confidence || !parcelType) {
      return;
    } // Only emit for actually estimated delivery times unless fireOnFallback is enabled


    if (!!(options !== null && options !== void 0 && options.fireOnFallback) && !["Estimated", "Fallback"].includes(labelType) || !(options !== null && options !== void 0 && options.fireOnFallback) && labelType !== "Estimated") return; // Fix up the options to match the analytics

    var eventContext = {
      confidence: Number(confidence),
      deliveryType: deliveryType,
      label: label || "",
      labelType: labelType,
      maxRange: maxRange !== null && maxRange !== void 0 ? maxRange : null,
      minRange: minRange !== null && minRange !== void 0 ? minRange : null,
      parcelType: parcelType,
      postcode: postcode,
      shortLabel: shortLabel !== null && shortLabel !== void 0 ? shortLabel : null,
      timestamp: timeStamp !== null && timeStamp !== void 0 ? timeStamp : null
    }; // Alright, we're good to go.

    emitDeliveryEstimate(eventContext); // Only trigger this effect for specific context changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, deliveryType, postcode, parcelType]);
}