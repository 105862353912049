import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

// @ts-ignore

/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
var defaultOptions = {};
export var CustomerDocument = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    query Customer {\n  customer {\n    id\n    displayName\n    email\n    dob\n    gender\n    firstName\n    lastName\n    phone\n    createdAt\n    club {\n      active\n      onFreeTrial\n      freeTrialEligible\n    }\n    membership {\n      id\n      linked\n      doubleSubscription\n      eligibleForMigration\n      subscription {\n        active\n        activeDaysRemaining\n        autoRenewing\n        freeTrialEligible\n        interval\n        latestMembershipPeriodEnd\n        onFreeTrial\n        purchasePrice\n        freeTrialDaysRemaining\n        isTeamMember\n      }\n    }\n    flybuys {\n      isLinked\n      maskedCardNumber\n    }\n    storeCredit {\n      credit {\n        amount\n      }\n      expiry\n    }\n    metaData {\n      customerUuid\n    }\n  }\n}\n    "])));
/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *   },
 * });
 */

export function useCustomerQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(CustomerDocument, options);
}
export function useCustomerLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(CustomerDocument, options);
}
export function useCustomerSuspenseQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(CustomerDocument, options);
}
export var UpdatePersonalDetailsDocument = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    mutation UpdatePersonalDetails($input: UpdatePersonalDetailsInput!) {\n  updatePersonalDetails(input: $input) {\n    __typename\n    ... on UpdatePersonalDetailsSuccessResponse {\n      message\n      data {\n        id\n        email\n        firstName\n        lastName\n        gender\n        dob\n        phone\n      }\n    }\n    ... on UpdatePersonalDetailsErrorResponse {\n      error {\n        status\n        message\n      }\n    }\n  }\n}\n    "])));
/**
 * __useUpdatePersonalDetailsMutation__
 *
 * To run a mutation, you first call `useUpdatePersonalDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonalDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonalDetailsMutation, { data, loading, error }] = useUpdatePersonalDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */

export function useUpdatePersonalDetailsMutation(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(UpdatePersonalDetailsDocument, options);
}