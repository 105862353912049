var _DO_NOT_USE_domEnvCon;

import { CaptureConsole } from "@sentry/integrations";
import * as logger from "@sentry/react";
import { embeddedConfig } from "@catchoftheday/env-config";
import { DO_NOT_USE_domEnvConfigLoader } from "@catchoftheday/env-config/lib/dom-loader/DO_NOT_USE_domEnvConfigLoader.js";

var _ref = (_DO_NOT_USE_domEnvCon = DO_NOT_USE_domEnvConfigLoader()) !== null && _DO_NOT_USE_domEnvCon !== void 0 ? _DO_NOT_USE_domEnvCon : {},
    SENTRY_DSN_PUBLIC = _ref.SENTRY_DSN_PUBLIC,
    _ref$ENV_NAME_PUBLIC = _ref.ENV_NAME_PUBLIC,
    ENV_NAME_PUBLIC = _ref$ENV_NAME_PUBLIC === void 0 ? "" : _ref$ENV_NAME_PUBLIC;

var VERSION = embeddedConfig.VERSION;
logger.init({
  dsn: SENTRY_DSN_PUBLIC,
  environment: ENV_NAME_PUBLIC,
  release: VERSION,
  ignoreErrors: [/Failed to fetch/i, /NetworkError/i, /Network Error/i, /Load failed/i, /aborted/i, /Timeout exceeded/i, "TypeError: cancelled", "cancelled", "SyntaxError: Unexpected token '<'", "No error message"],
  tracesSampler: function tracesSampler(samplingContext) {
    if (samplingContext.transactionContext.name.includes("Unexpected token")) {
      return 0.01;
    } else {
      return 0.5;
    }
  },
  integrations: [new CaptureConsole({
    levels: ["warn", "error"]
  })]
});
export default logger;