import queryString from "query-string";
import { urlUpdate } from "../../url/index.js";
export var updateQueryParams = function updateQueryParams(_ref) {
  var queryParams = _ref.queryParams,
      _ref$returnUrl = _ref.returnUrl,
      returnUrl = _ref$returnUrl === void 0 ? false : _ref$returnUrl,
      _ref$usePushState = _ref.usePushState,
      usePushState = _ref$usePushState === void 0 ? false : _ref$usePushState;
  var searchParams = new URLSearchParams(globalThis.location.search);
  queryParams.forEach(function (_ref2) {
    var key = _ref2.key,
        value = _ref2.value;

    if (value === undefined || value === null || value === "") {
      searchParams["delete"](key);
    } else {
      searchParams.set(key, value.toString());
    }
  });

  if (returnUrl) {
    return searchParams.toString();
  } else {
    urlUpdate({
      queryObject: queryString.parse(searchParams.toString()),
      usePushState: usePushState
    });
  }
};