import { trackStructEvent } from "@snowplow/browser-tracker";
/**
 *
 * Fires event when user clicks on searchbox
 */

export function emitSearchboxImpression() {
  trackStructEvent({
    category: "search",
    action: "select_searchbox"
  });
}