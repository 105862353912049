import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import logger from "./configure";

function logFunctionFactory(level) {
  return function (arg) {
    var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return logger.captureException(arg, _objectSpread({
      level: level
    }, opts));
  };
}

var loggy = {
  info: logFunctionFactory("info"),
  error: logFunctionFactory("error"),
  log: logFunctionFactory("log"),
  debug: logFunctionFactory("debug"),
  warning: logFunctionFactory("warning")
};
export default loggy;