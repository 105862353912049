/**
 * 🚨 DO NOT USE THIS FILE! 🚨
 *
 * The `loggy` package needs to be configured as early as possible,
 * so we can't wait for the `env-config` data to be loaded into React via
 * the `withEnvConfig` HOC.
 *
 * This file provides a way to configure Sentry as early as possible, by directly
 * accessing the `env-config` data from the `__NEXT_DATA__` provided in the DOM
 * by Next's `getInitialProps`.
 *
 * It's hacky, but is the only way to get these values into Sentry before the app
 * is rendered.
 *
 * As such, this file is exclusive to the `loggy` package, and should not be used.
 */
export function DO_NOT_USE_domEnvConfigLoader() {
  var _window$__NEXT_DATA__, _window$__NEXT_DATA__2;

  if (false) return null; // If the envConfig is already in the DOM, use it.

  if ((_window$__NEXT_DATA__ = window.__NEXT_DATA__) !== null && _window$__NEXT_DATA__ !== void 0 && (_window$__NEXT_DATA__2 = _window$__NEXT_DATA__.props) !== null && _window$__NEXT_DATA__2 !== void 0 && _window$__NEXT_DATA__2.envConfig) return window.__NEXT_DATA__.props.envConfig;
  var nextDataElement = document.getElementById("__NEXT_DATA__");

  if (!nextDataElement) {
    console.error('Could not find "__NEXT_DATA__" element in DOM');
    return null;
  }

  var nextData = JSON.parse(nextDataElement.innerText);

  if (!nextData || !nextData.props || !nextData.props.envConfig) {
    console.error('Could not find "envConfig" in "__NEXT_DATA__"');
    return null;
  }

  return nextData.props.envConfig;
}