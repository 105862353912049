import React from "react";
import { Slide, toast as RTtoast, ToastContainer as RTToastContainer } from "react-toastify";
import { ToastStyle } from "./Toast.styles";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ToastContainer = function ToastContainer(_ref) {
  var containerId = _ref.containerId,
      containerStyles = _ref.containerStyles,
      toastStyles = _ref.toastStyles,
      _ref$position = _ref.position,
      position = _ref$position === void 0 ? "top-right" : _ref$position,
      _ref$autoClose = _ref.autoClose,
      autoClose = _ref$autoClose === void 0 ? 5000 : _ref$autoClose,
      _ref$closeButton = _ref.closeButton,
      closeButton = _ref$closeButton === void 0 ? false : _ref$closeButton,
      _ref$pauseOnHover = _ref.pauseOnHover,
      pauseOnHover = _ref$pauseOnHover === void 0 ? true : _ref$pauseOnHover,
      _ref$closeOnClick = _ref.closeOnClick,
      closeOnClick = _ref$closeOnClick === void 0 ? false : _ref$closeOnClick;
  return ___EmotionJSX(ToastStyle, {
    toastStyles: toastStyles
  }, ___EmotionJSX(RTToastContainer, {
    style: containerStyles,
    hideProgressBar: true,
    transition: Slide,
    draggable: false,
    enableMultiContainer: true,
    containerId: containerId,
    position: position,
    autoClose: autoClose,
    closeButton: closeButton,
    pauseOnHover: pauseOnHover,
    closeOnClick: closeOnClick,
    pauseOnFocusLoss: false
  }));
};
export var toast = function toast(content, options) {
  return RTtoast(content, options);
};

toast.update = function (id, options) {
  return RTtoast.update(id, options);
};

ToastContainer.displayName = "ToastContainer";
export default ToastContainer;