import { trackStructEvent } from "@snowplow/browser-tracker";
import { ONEPASS_ACTION_LINKING_RESULT, ONEPASS_CATEGORY, ONEPASS_NOTIFICATION_LABEL_ERROR, ONEPASS_NOTIFICATION_LABEL_SUCCESS } from "@analytics/consts";
export function emitLinkingNotification(_ref) {
  var type = _ref.type,
      message = _ref.message;
  trackStructEvent({
    category: ONEPASS_CATEGORY,
    action: ONEPASS_ACTION_LINKING_RESULT,
    label: type === "success" ? ONEPASS_NOTIFICATION_LABEL_SUCCESS : ONEPASS_NOTIFICATION_LABEL_ERROR,
    property: message
  });
}