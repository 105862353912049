import { compose, system } from "styled-system";
export * from "styled-system";
export var visibilityProp = system({
  visibility: {
    property: "visibility"
  }
});
export var textTransformProp = system({
  textTransform: {
    property: "textTransform"
  }
});
export var textDecorationProp = system({
  textDecoration: {
    property: "textDecoration"
  }
});
export var hoverColorProp = system({
  hoverColor: {
    property: "color",
    scale: "colors"
  }
});
export var hoverTextDecorationProp = system({
  hoverTextDecoration: {
    property: "textDecoration"
  }
});
export var hoverBorderColorProp = system({
  hoverBorderColor: {
    property: "borderColor",
    scale: "colors"
  }
});
export var focusBorderColorProp = system({
  focusBorderColor: {
    property: "borderColor",
    scale: "colors"
  }
});
export var focusOutlineProp = system({
  focusOutline: {
    property: "outline"
  }
});
export var placeholderTextProps = system({
  placeholderFontSize: {
    property: "fontSize",
    scale: "fontSizes"
  },
  placeholderFontWeight: {
    property: "fontWeight",
    scale: "fontWeights"
  }
});

var _float = compose(system({
  clear: {
    property: "clear"
  }
}), system({
  "float": {
    property: "float"
  }
}));

export { _float as float };
export var cursorProp = system({
  cursor: {
    property: "cursor"
  }
});
export var gridReplacementProps = compose(system({
  gap: {
    property: "gap",
    scale: "space"
  }
}), system({
  rowGap: {
    property: "rowGap",
    scale: "space"
  }
}), system({
  columnGap: {
    property: "columnGap",
    scale: "space"
  }
}));
export var pointerEvents = system({
  pointerEvents: {
    property: "pointerEvents"
  }
});