import { trackSelfDescribingEvent } from "@snowplow/browser-tracker";
export function emitRecommenderImpression(_ref) {
  var _ref$source_type = _ref.source_type,
      source_type = _ref$source_type === void 0 ? null : _ref$source_type,
      from_product_ids = _ref.from_product_ids,
      from_product_offer_id = _ref.from_product_offer_id,
      from_product_displayed_price = _ref.from_product_displayed_price,
      recommended_product_count = _ref.recommended_product_count;
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:au.com.catch/recommender_impression/jsonschema/1-0-2",
      data: {
        source_type: source_type,
        from_product_ids: from_product_ids,
        from_product_offer_id: from_product_offer_id,
        from_product_displayed_price: from_product_displayed_price,
        recommended_product_count: recommended_product_count
      }
    }
  });
}