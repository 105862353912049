export function emitTrackingPixel(url) {
  var existingPixel = document.querySelector("img[src=\"".concat(url, "\"]"));

  if (existingPixel) {
    return;
  }

  var img = new Image();
  img.src = url;
  img.width = 1;
  img.height = 1;
  img.style.display = "none";
  document.body.appendChild(img);
}