import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import { useEffect } from "react";
var subscribers = [];

var subscribe = function subscribe(filter, callback) {
  if (filter === undefined || filter === null) return undefined;
  if (callback === undefined || callback === null) return undefined;
  subscribers = [].concat(_toConsumableArray(subscribers), [[filter, callback]]);
  return function () {
    subscribers = subscribers.filter(function (subscriber) {
      return subscriber[1] !== callback;
    });
  };
};

export function dispatchEventBus(event) {
  subscribers.forEach(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        filter = _ref2[0],
        callback = _ref2[1];

    if (typeof filter === "object" && filter.type !== event.type) return;
    if (typeof filter === "function" && !filter(event)) return;
    callback(event);
  });
}
export var useEventBus = function useEventBus(type, callback) {
  var deps = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(function () {
    return subscribe(type, callback);
  }, deps);
  return dispatchEventBus;
};
export default useEventBus;