import { trackStructEvent } from "@snowplow/browser-tracker";
import { ONEPASS_ACTION_REWARD, ONEPASS_CATEGORY } from "@analytics/consts";

function getLabel(label) {
  switch (label) {
    case "apply":
      return "Apply";

    case "error":
      return "Error";

    case "click":
    default:
      return "Click";
  }
}

export function emitRewardAction(_ref) {
  var label = _ref.label,
      property = _ref.property;
  trackStructEvent({
    category: ONEPASS_CATEGORY,
    action: ONEPASS_ACTION_REWARD,
    label: getLabel(label),
    property: property
  });
}