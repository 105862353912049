import { dispatchEventBus } from "../useEventBus";
export var TRIGGER_ONEPASS_UPSELL_MODAL_EVENT = {
  type: "@global/TRIGGER_ONEPASS_UPSELL_MODAL"
};
export var triggerOnePassUpsellModal = function triggerOnePassUpsellModal() {
  var payload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  dispatchEventBus({
    type: TRIGGER_ONEPASS_UPSELL_MODAL_EVENT.type,
    payload: payload
  });
};