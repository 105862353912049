import Url from "url-parse";
export var urlUpdate = function urlUpdate(_ref) {
  var _globalThis$location, _globalThis$history;

  var _ref$href = _ref.href,
      href = _ref$href === void 0 ? globalThis === null || globalThis === void 0 ? void 0 : (_globalThis$location = globalThis.location) === null || _globalThis$location === void 0 ? void 0 : _globalThis$location.href : _ref$href,
      queryObject = _ref.queryObject,
      usePushState = _ref.usePushState;
  var urlObject = new Url(href, true);
  urlObject.set("query", queryObject);

  if (globalThis !== null && globalThis !== void 0 && (_globalThis$history = globalThis.history) !== null && _globalThis$history !== void 0 && _globalThis$history.replaceState) {
    if (usePushState) {
      globalThis.history.pushState(queryObject, "", urlObject.href);
    } else {
      globalThis.history.replaceState(queryObject, "", urlObject.href);
    }
  }
};