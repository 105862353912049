import { trackStructEvent } from "@snowplow/browser-tracker";
export function fireABEvents(experiments) {
  experiments.forEach(function (exp) {
    trackStructEvent({
      category: "AB Test",
      action: "Variation Chosen",
      label: exp.name || undefined,
      property: exp.variation,
      value: exp.id ? parseInt(exp.id) : undefined
    });
  });
}