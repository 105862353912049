import { trackStructEvent } from "@snowplow/browser-tracker";
import { ONEPASS_CATEGORY } from "@analytics/consts";

function getLabel(action) {
  switch (action) {
    case "cancel":
      return "Cancel";

    case "proceed":
      return "Proceed";

    default:
      return "Cancel";
  }
}

export function emitLinkingModalAction(_ref) {
  var action = _ref.action,
      label = _ref.label;
  trackStructEvent({
    category: ONEPASS_CATEGORY,
    action: action,
    label: getLabel(label)
  });
}