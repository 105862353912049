var Trigger;

(function (Trigger) {
  Trigger[Trigger["ADD_TO_CART"] = 0] = "ADD_TO_CART";
  Trigger[Trigger["BUY_NOW"] = 1] = "BUY_NOW";
})(Trigger || (Trigger = {}));

export var Events;

(function (Events) {
  Events["PRODUCT_VIEW"] = "product_view";
  Events["ADD_TO_CART"] = "add_to_cart";
  Events["ADD_TO_WISHLIST"] = "add_to_wishlist";
  Events["REMOVE_ITEM_FROM_CART"] = "remove_from_cart";
  Events["CART"] = "cart";
  Events["USER"] = "user";
  Events["WISHLIST"] = "wishlist";
  Events["PRODUCT_IMPRESSION"] = "product_impression";
  Events["EVENT_IMPRESSION"] = "event_impression";
  Events["PRODUCT_GROUP_IMPRESSION"] = "product_group_impression";
  Events["SEARCH_REFINEMENT_IMPRESSION"] = "search_refinement_impression";
  Events["RECOMMENDER_IMPRESSION"] = "recommender_impression";
  Events["PRODUCT_LIST_INTERACTION"] = "product_list_interaction";
  Events["RECOMMENDER_REQUEST"] = "recommender_request";
  Events["EVENT_CLICK"] = "event_click";
  Events["DELIVERY_TYPE"] = "delivery_type";
  Events["DELIVERY_ESTIMATE"] = "delivery_estimate";
  Events["SALES_RULE_TAG_CLICK"] = "sales_rule_tag_click";
  Events["SALES_RULE_TAG_IMPRESSION"] = "sales_rule_tag_impression";
})(Events || (Events = {}));