import { trackStructEvent } from "@snowplow/browser-tracker";
import { ONEPASS_ACTION_MIGRATION_MODAL, ONEPASS_CATEGORY } from "@analytics/consts";

function getLabel(label) {
  switch (label) {
    case "click":
      return "Click";

    case "close":
      return "Close";

    default:
      return "Click";
  }
}

export function emitMigrationModalAction(label) {
  trackStructEvent({
    category: ONEPASS_CATEGORY,
    action: ONEPASS_ACTION_MIGRATION_MODAL,
    label: getLabel(label)
  });
}