import { trackSelfDescribingEvent } from "@snowplow/browser-tracker";
export function emitProductImpression(_ref) {
  var offer_id = _ref.offer_id,
      source_id = _ref.source_id,
      source_position = _ref.source_position,
      source_type = _ref.source_type,
      product_id = _ref.product_id,
      product_title = _ref.product_title,
      displayed_price = _ref.displayed_price,
      from_product_ids = _ref.from_product_ids,
      from_product_offer_id = _ref.from_product_offer_id,
      from_product_displayed_price = _ref.from_product_displayed_price;
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:au.com.catch/product_impression/jsonschema/2-0-2",
      data: {
        offer_id: offer_id,
        source_id: source_id || null,
        source_position: source_position || null,
        source_type: source_type || null,
        product_id: product_id,
        product_title: product_title,
        displayed_price: displayed_price,
        from_product_ids: from_product_ids,
        from_product_offer_id: from_product_offer_id,
        from_product_displayed_price: from_product_displayed_price
      }
    }
  });
}