import { useEffect, useState } from "react";

var constructMessage = function constructMessage(payload) {
  return {
    type: "PAGE_VIEW",
    payload: payload
  };
};

export var usePageViewMessage = function usePageViewMessage(_ref) {
  var sendMessage = _ref.sendMessage,
      pageViewPayload = _ref.pageViewPayload,
      loading = _ref.loading;

  var _useState = useState(false),
      isSent = _useState[0],
      setIsSent = _useState[1];

  useEffect(function () {
    if (!loading && !isSent) {
      sendMessage(constructMessage(pageViewPayload));
      setIsSent(true);
    }
  }, [loading, isSent, sendMessage, pageViewPayload]);
  return constructMessage(pageViewPayload).payload;
};