export function cancellablePromise(promise) {
  var isCancelled = {
    value: false
  };
  var wrappedPromise = new Promise(function (res, rej) {
    promise.then(function (d) {
      return isCancelled.value ? rej("cancelled") : res(d);
    })["catch"](function (e) {
      rej(isCancelled.value ? "cancelled" : e);
    });
  });
  return {
    promise: wrappedPromise,
    cancel: function cancel() {
      isCancelled.value = true;
    }
  };
}