import React from "react";
import { Box, ContentPlaceHolder } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var PersonalDetailsContainerPlaceholder = function PersonalDetailsContainerPlaceholder() {
  return ___EmotionJSX(Box, {
    margin: "0 auto"
  }, ___EmotionJSX(ContentPlaceHolder, {
    speed: 2,
    width: "100%",
    height: "360px"
  }, ___EmotionJSX("rect", {
    width: "100%",
    height: "360px",
    x: "0",
    y: "0"
  })));
};