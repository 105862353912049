import { useEffect } from "react";
import { TopicStats } from "./TopicStats.model";

var constructMessage = function constructMessage(topics) {
  return {
    type: "SUB",
    payload: {
      topics: topics
    }
  };
};

export var useSocialUrgencyMessage = function useSocialUrgencyMessage(_ref) {
  var sendMessage = _ref.sendMessage,
      lastMessage = _ref.lastMessage,
      topicInputs = _ref.topicInputs,
      salesChannelCode = _ref.salesChannelCode;
  var topics = topicInputs.length ? topicInputs.map(function (input) {
    return "".concat(input.context, ":").concat(input.id, ":").concat(salesChannelCode.toLowerCase(), ":viewers");
  }).join("|") : "";
  useEffect(function () {
    if (topics.length > 0) {
      sendMessage(constructMessage(topics.split("|")));
    }
  }, [topics, sendMessage]);

  var _ref2 = (lastMessage === null || lastMessage === void 0 ? void 0 : lastMessage.payload) || {},
      topic = _ref2.topic;

  if (topic && topics.indexOf(topic) >= 0) {
    var _lastMessage$payload, _lastMessage$payload2;

    var id = topic.split(":")[1];
    return {
      id: id,
      stats: lastMessage !== null && lastMessage !== void 0 && (_lastMessage$payload = lastMessage.payload) !== null && _lastMessage$payload !== void 0 && _lastMessage$payload.stats ? new TopicStats(lastMessage === null || lastMessage === void 0 ? void 0 : (_lastMessage$payload2 = lastMessage.payload) === null || _lastMessage$payload2 === void 0 ? void 0 : _lastMessage$payload2.stats) : new TopicStats()
    };
  }

  return {
    id: null,
    stats: new TopicStats()
  };
};