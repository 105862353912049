import { useEffect, useState } from "react";
export var useIsSSR = function useIsSSR() {
  var _useState = useState(true),
      isSSR = _useState[0],
      setIsSSR = _useState[1];

  useEffect(function () {
    setIsSSR(false);
  }, []);
  return isSSR;
};