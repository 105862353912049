import React from "react";
import { toast } from "@catchoftheday/cg-components";
import { ToastContent } from "./components/index.js";
import { HEADER_TOAST_CONTAINER_ID } from "./consts.js";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var updateToast = function updateToast(_ref) {
  var toastContent = _ref.toastContent,
      toastId = _ref.toastId,
      autoClose = _ref.autoClose;
  toast.update(toastId, {
    containerId: HEADER_TOAST_CONTAINER_ID,
    render: ___EmotionJSX(ToastContent, toastContent),
    autoClose: autoClose
  });
};