import { useEffect, useState } from "react";
import axios from "axios";
import { parseQueryString } from "@catchoftheday/utilities";
import { initialize } from "../initialize";
export function Snowplow(_ref) {
  var config = _ref.config,
      _ref$disableAutomatic = _ref.disableAutomaticTracking,
      disableAutomaticTracking = _ref$disableAutomatic === void 0 ? false : _ref$disableAutomatic;

  var _useState = useState(false),
      calledGuard = _useState[0],
      setCalledGuard = _useState[1]; // The guard endpoint is called to migrate subdomain cookies to the root domain, eg from www.catch.com.au and m.catch.com.au to catch.com.au.
  // This is done so that all users will always use the same set of cookeis regardless of which subdomain they are on since the mobile site is slowly being deprecated as it is identical to the regular site on the new SPAs.
  // This fixes an issue where users on the SPAs may switch domains causing us to be unable to track the user journey as the SPAs do not implement any redirect logic for the subdomain as that would cause performance issues (and from the SPA perspective they are identical responsive pages).


  useEffect(function () {
    if (parseQueryString(location.search).guard !== "off") {
      axios.get("/guard").then(function () {
        return setCalledGuard(true);
      });
    } else {
      setCalledGuard(true);
    }
  }, []); // We must wait for the guard endpoint call to finish updating the cookies before initializing snowplow so that it picks up the migrated cookies

  useEffect(function () {
    if (calledGuard) {
      initialize({
        snowplowConfig: config,
        disableAutomaticTracking: disableAutomaticTracking
      });
    }
  }, [config, disableAutomaticTracking, calledGuard]);
  return null;
}