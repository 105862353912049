import { useEffect, useRef } from "react";
export function useIsMounted() {
  var ref = useRef(false);
  useEffect(function () {
    ref.current = true;
    return function () {
      ref.current = false;
    };
  }, []);
  return function () {
    return ref.current;
  };
}