import React from "react";
import { Box, Flex, IconTick, Image, LinkButton, Text, Truncate } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
var StatusColour;

(function (StatusColour) {
  StatusColour["success"] = "primary";
  StatusColour["error"] = "error";
  StatusColour["info"] = "secondary";
  StatusColour["tertiary"] = "tertiary";
})(StatusColour || (StatusColour = {}));

export var ToastContent = function ToastContent(_ref) {
  var items = _ref.items,
      _ref$showCheckoutOnSu = _ref.showCheckoutOnSuccess,
      showCheckoutOnSuccess = _ref$showCheckoutOnSu === void 0 ? false : _ref$showCheckoutOnSu;
  var showCheckoutButton = showCheckoutOnSuccess && items.some(function (item) {
    return item.status === "success";
  });
  return ___EmotionJSX(Flex, {
    flexDirection: "column"
  }, items.map(function (_ref2, index) {
    var message = _ref2.message,
        status = _ref2.status,
        imageUrl = _ref2.imageUrl,
        title = _ref2.title,
        showTickOnSuccess = _ref2.showTickOnSuccess;
    return ___EmotionJSX(Flex, {
      key: "".concat(title, "-").concat(index),
      pb: "8px"
    }, ___EmotionJSX(Box, {
      border: "1px solid",
      borderColor: "ui.borderColor"
    }, ___EmotionJSX(Image, {
      src: imageUrl,
      alt: title,
      width: "76px"
    })), ___EmotionJSX(Box, {
      pl: "12px",
      color: "text"
    }, ___EmotionJSX(Truncate, {
      m: 0,
      color: "text",
      fontWeight: "normal",
      lines: 2
    }, title), ___EmotionJSX(Flex, {
      alignItems: "center"
    }, showTickOnSuccess && status === "success" && ___EmotionJSX(IconTick, {
      mr: "10px",
      mt: "5px",
      width: "15px",
      height: "26px",
      "aria-hidden": "true",
      color: "primary"
    }), ___EmotionJSX(Text, {
      fontWeight: "600",
      mt: "5px",
      mb: "0px",
      color: StatusColour[status] ? StatusColour[status] : StatusColour.info
    }, message))));
  }), showCheckoutButton && ___EmotionJSX(LinkButton, {
    sizeVariant: "sm",
    mt: "10px",
    href: "/checkout/payment"
  }, "Checkout"));
};