import { CustomerDocument } from "../../generated/graphql";
export var getApolloMockCustomer = function getApolloMockCustomer(customer) {
  return {
    request: {
      query: CustomerDocument
    },
    result: {
      data: {
        customer: customer
      }
    }
  };
};