import { useCallback, useState } from "react";
import { sessionStorage } from "../../localStorage";
export var useSessionStorage = function useSessionStorage(key) {
  var initialValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

  var _useState = useState(function () {
    var item = sessionStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  }),
      storedValue = _useState[0],
      setStoredValue = _useState[1];

  var setValue = useCallback(function (value) {
    setStoredValue(value);
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key]);
  return [storedValue, setValue];
};