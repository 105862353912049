import { trackStructEvent } from "@snowplow/browser-tracker";
import { ONEPASS_ACTION_UPSELL_NUDGE, ONEPASS_CATEGORY } from "@analytics/consts";

function getLabel(label) {
  switch (label) {
    case "freeTrial":
      return "Start free 30 day trial";

    case "join":
      return "Join OnePass";

    case "login":
      return "Login";

    case "link":
      return "Link";

    case "activate":
      return "Activate";

    default:
      return "Start free 30 day trial";
  }
}

export function emitUpsellNudgeAction(label) {
  trackStructEvent({
    category: ONEPASS_CATEGORY,
    action: ONEPASS_ACTION_UPSELL_NUDGE,
    label: getLabel(label)
  });
}