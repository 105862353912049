import { trackSelfDescribingEvent } from "@snowplow/browser-tracker";
export function emitRecommenderRequest(_ref) {
  var source_type = _ref.source_type,
      _ref$pre_hydration_pr = _ref.pre_hydration_product_count,
      pre_hydration_product_count = _ref$pre_hydration_pr === void 0 ? null : _ref$pre_hydration_pr,
      _ref$post_hydration_p = _ref.post_hydration_product_count,
      post_hydration_product_count = _ref$post_hydration_p === void 0 ? null : _ref$post_hydration_p,
      _ref$hydration_error_ = _ref.hydration_error_count,
      hydration_error_count = _ref$hydration_error_ === void 0 ? null : _ref$hydration_error_,
      _ref$from_product_ids = _ref.from_product_ids,
      from_product_ids = _ref$from_product_ids === void 0 ? null : _ref$from_product_ids,
      _ref$from_product_off = _ref.from_product_offer_id,
      from_product_offer_id = _ref$from_product_off === void 0 ? null : _ref$from_product_off,
      _ref$from_product_dis = _ref.from_product_displayed_price,
      from_product_displayed_price = _ref$from_product_dis === void 0 ? null : _ref$from_product_dis,
      _ref$rec_version = _ref.rec_version,
      rec_version = _ref$rec_version === void 0 ? 0 : _ref$rec_version,
      _ref$cold_start_versi = _ref.cold_start_version,
      cold_start_version = _ref$cold_start_versi === void 0 ? null : _ref$cold_start_versi,
      _ref$page_limit = _ref.page_limit,
      page_limit = _ref$page_limit === void 0 ? null : _ref$page_limit,
      _ref$page_offset = _ref.page_offset,
      page_offset = _ref$page_offset === void 0 ? null : _ref$page_offset;
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:au.com.catch/recommender_request/jsonschema/1-0-3",
      data: {
        source_type: source_type,
        pre_hydration_product_count: pre_hydration_product_count,
        post_hydration_product_count: post_hydration_product_count,
        hydration_error_count: hydration_error_count,
        from_product_ids: from_product_ids,
        from_product_offer_id: from_product_offer_id,
        from_product_displayed_price: from_product_displayed_price,
        rec_version: rec_version,
        cold_start_version: cold_start_version,
        page_limit: page_limit,
        page_offset: page_offset
      }
    }
  });
}