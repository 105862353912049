import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["id", "label", "name"];
import React from "react";
import { Box, FormError, FormInput, Label } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var RequiredInput = function RequiredInput(_ref) {
  var id = _ref.id,
      label = _ref.label,
      name = _ref.name,
      rest = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(Box, null, ___EmotionJSX(Label, {
    htmlFor: id,
    required: true
  }, label), ___EmotionJSX(FormInput, _extends({
    id: id,
    name: name
  }, rest)), ___EmotionJSX(FormError, {
    name: name
  }));
};