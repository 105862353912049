/**
 * EnvConfig Utilities
 *
 * The following types and functions are used internally by the `env-config` package;
 * you probably don't need to worry about them.
 *
 * Go on, collapse the region and forget about them 🙃.
 */

/**
 * Converts a record to a record with keys without the `_PUBLIC` suffix.
 */
export function mapWithoutPublicSuffix(records) {
  return Object.keys(records).reduce(function (result, key) {
    var keyWithoutPublicSuffix = key.endsWith("_PUBLIC") ? key.slice(0, -7) // Remove `_PUBLIC` suffix
    : key;
    result[keyWithoutPublicSuffix] = records[key]; // Casting because TS doesn't know that `keyWithoutPublicSuffix` is a key of `T` and I can't figure out how to fix it.

    return result;
  }, {});
}
/**
 * Composes the inherited config from the public config.
 * Public config keys can be overridden by providing a private config key with the `_PUBLIC` omitted.
 * e.g. `GRAPHQL_ENDPOINT_PUBLIC` can be overridden by providing `GRAPHQL_ENDPOINT`.
 * @private
 */

export function composeInheritedConfig(publicEnvConfig) {
  return mapWithoutPublicSuffix(publicEnvConfig);
}
/**
 * Automatically allow the public config to be overridden by the private config
 * without explicit mapping on the `privateEnvConfig` object.
 * @private
 */

export function loadPrivateOverrides(publicEnvConfig, source) {
  var mapped = mapWithoutPublicSuffix(publicEnvConfig);

  for (var key in mapped) {
    var value = source[key]; // Check if an override exists.

    if (value !== undefined) {
      // @ts-expect-error because `mapped` is so strongly-typed, it doesn't like us assigning a somewhat unknown value to a "known" key.
      mapped[key] = value;
    }
  }

  return mapped;
}