import React from "react";
import { toast } from "@catchoftheday/cg-components";
import { ToastContent } from "./components/index.js";
import { HEADER_TOAST_CONTAINER_ID } from "./consts.js";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var createToast = function createToast(_ref) {
  var toastContent = _ref.toastContent,
      toastId = _ref.toastId,
      _ref$autoClose = _ref.autoClose,
      autoClose = _ref$autoClose === void 0 ? 5000 : _ref$autoClose;
  return toast(___EmotionJSX(ToastContent, toastContent), {
    containerId: HEADER_TOAST_CONTAINER_ID,
    position: "top-center",
    autoClose: autoClose,
    toastId: toastId
  });
};