import { trackStructEvent } from "@snowplow/browser-tracker";
import { ONEPASS_CATEGORY } from "@analytics/consts";

function getLabel(label) {
  switch (label) {
    case "freeTrial":
      return "Start free 30 day trial";

    case "join":
      return "Join OnePass";

    case "login":
      return "Login";

    case "link":
      return "Link";

    case "learnMore":
      return "Learn More";

    case "close":
      return "Close/Background";

    default:
      return "Start free 30 day trial";
  }
}

export function emitUpsellModalAction(_ref) {
  var _ref$action = _ref.action,
      action = _ref$action === void 0 ? "Upsell Modal" : _ref$action,
      label = _ref.label;
  trackStructEvent({
    category: ONEPASS_CATEGORY,
    action: action,
    label: getLabel(label)
  });
}