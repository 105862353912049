/**
 * Adds a trailing slash to a URL if it doesn't already have one
 * @param url Partial or absolute URL string
 * @returns URL string with trailing slash
 */
export var addTrailingSlash = function addTrailingSlash(url) {
  var urlParts = url.split("?");
  var rest = urlParts[0];

  if (rest.endsWith("/")) {
    return url;
  }

  var search = urlParts[1];
  return "".concat("".concat(rest, "/"), search ? "?".concat(search) : "");
};