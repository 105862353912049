import { Events } from "../types";
import { submitSPEvents } from "./emitSnowplowEvent";
export function emitProductView(_ref) {
  var offer_id = _ref.offer_id,
      source_position = _ref.source_position,
      source_type = _ref.source_type,
      source_id = _ref.source_id;
  submitSPEvents([{
    context: Events.PRODUCT_VIEW,
    options: {
      offer_id: offer_id,
      source_position: source_position,
      source_type: source_type,
      source_id: source_id
    }
  }]);
}