export function onDOMNodeAdded(_ref) {
  var _elements = _ref.elements,
      callback = _ref.callback;

  var elements = _elements.map(function (node) {
    return node.toLowerCase();
  });

  function matches(node) {
    return elements.includes(node.nodeName.toLowerCase()) || Array.from(node.childNodes).some(matches);
  }

  var config = {
    childList: true,
    subtree: true
  };
  var observer = new MutationObserver(function (mutationsList) {
    var elementAdded = mutationsList.some(function (mutation) {
      return mutation.type === "childList" && Array.from(mutation.addedNodes).some(matches);
    });

    if (elementAdded) {
      callback();
    }
  });
  observer.observe(document.body, config);
  return function () {
    return observer.disconnect();
  };
}