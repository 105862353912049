import { PRODUCT_LIST_INTERACTION_TYPES } from "../consts";
import { Events } from "../types";
import { submitSPEvents } from "./emitSnowplowEvent";
export function emitProductListInteraction(_ref) {
  var interaction = _ref.interaction,
      category = _ref.category,
      category_secondary = _ref.category_secondary,
      category_tertiary = _ref.category_tertiary,
      product_id = _ref.product_id,
      offer_id = _ref.offer_id,
      source_id = _ref.source_id,
      source_type = _ref.source_type,
      source_position = _ref.source_position,
      srtrev = _ref.srtrev,
      from_product_ids = _ref.from_product_ids,
      from_product_offer_id = _ref.from_product_offer_id,
      from_product_displayed_price = _ref.from_product_displayed_price;
  submitSPEvents([{
    context: Events.PRODUCT_LIST_INTERACTION,
    options: {
      interaction: interaction ? PRODUCT_LIST_INTERACTION_TYPES[interaction] : PRODUCT_LIST_INTERACTION_TYPES.click,
      category: category || null,
      category_secondary: category_secondary || null,
      category_tertiary: category_tertiary || null,
      product_id: product_id,
      offer_id: offer_id,
      source_id: source_id || null,
      source_type: source_type || null,
      source_position: source_position || null,
      srtrev: srtrev,
      from_product_ids: from_product_ids,
      from_product_offer_id: from_product_offer_id,
      from_product_displayed_price: from_product_displayed_price
    }
  }]);
}