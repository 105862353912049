import { Events } from "../types";
import { submitSPEvents } from "./emitSnowplowEvent";
export function emitRemoveItemFromCart(_ref) {
  var source_id = _ref.source_id,
      source_type = _ref.source_type,
      source_platform = _ref.source_platform,
      source_position = _ref.source_position,
      offer_id = _ref.offer_id,
      position = _ref.position,
      event_id = _ref.event_id,
      customer_id = _ref.customer_id;
  submitSPEvents([{
    context: Events.REMOVE_ITEM_FROM_CART,
    options: {
      source_id: source_id,
      source_type: source_type,
      source_platform: source_platform,
      source_position: source_position,
      offer_id: offer_id,
      position: position,
      event_id: event_id,
      customer_id: customer_id
    }
  }, {
    context: Events.CART
  }]);
}