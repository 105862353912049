import { minusOnePx as minusOnePxFunc } from "../utils/minusOnePx.js";
import { useMedia } from "./useMedia/index.js";
import { useTheme } from "./useTheme.js";
export var useBreakpoint = function useBreakpoint() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$minusOnePx = _ref.minusOnePx,
      minusOnePx = _ref$minusOnePx === void 0 ? false : _ref$minusOnePx;

  var _useTheme = useTheme(),
      breakpoints = _useTheme.breakpoints;

  var mobileBp = minusOnePx ? minusOnePxFunc(breakpoints.md, true) : breakpoints.md;
  var desktopBp = minusOnePx ? minusOnePxFunc(breakpoints.lg, true) : breakpoints.lg;
  var isMobile = !!useMedia(["(max-width: ".concat(mobileBp, ")")], [true]);
  var isDesktop = !!useMedia(["(max-width: ".concat(desktopBp, ")")], [true]);
  return {
    isMobile: isMobile,
    isDesktop: isDesktop
  };
};