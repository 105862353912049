import { trackStructEvent } from "@snowplow/browser-tracker";
import { Events } from "./../types";
import { submitSPEvents } from "./emitSnowplowEvent";
export function emitAddToWishlist(_ref) {
  var product_id = _ref.product_id,
      page_type = _ref.page_type,
      wishlist = _ref.wishlist,
      _ref$action = _ref.action,
      action = _ref$action === void 0 ? "Add" : _ref$action,
      position = _ref.position,
      quantity = _ref.quantity,
      source_type = _ref.source_type,
      event_id = _ref.event_id,
      event_title = _ref.event_title;
  trackStructEvent({
    category: "wishlist",
    action: action,
    property: "wishlist"
  });
  var events = [{
    context: Events.WISHLIST,
    options: {
      page_type: page_type,
      product_id: product_id
    }
  }];

  if (wishlist && action === "Add") {
    events.push({
      context: Events.ADD_TO_WISHLIST,
      options: {
        wishlist: wishlist,
        position: position,
        quantity: quantity,
        source_type: source_type,
        event_id: event_id,
        event_title: event_title
      }
    });
  }

  submitSPEvents(events);
}