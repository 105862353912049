import { trackStructEvent } from "@snowplow/browser-tracker";
import { Events } from "./../types";
import { submitSPEvents } from "./emitSnowplowEvent";
export function emitNotify(_ref) {
  var product_id = _ref.product_id,
      page_type = _ref.page_type,
      action = _ref.action;
  trackStructEvent({
    category: "Notify me",
    action: action,
    property: "notify_me"
  });
  submitSPEvents([{
    context: Events.WISHLIST,
    options: {
      page_type: page_type,
      product_id: product_id
    }
  }]);
}