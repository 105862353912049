import React from "react";
import { Flex } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var AuthLayout = function AuthLayout(_ref) {
  var children = _ref.children;
  return ___EmotionJSX(Flex, {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: {
      xs: "none",
      md: "brand.primary"
    },
    position: {
      xs: "relative",
      md: "absolute"
    },
    width: "100%",
    height: "100%"
  }, ___EmotionJSX(Flex, {
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "400px",
    textAlign: "center",
    flexDirection: "column",
    boxShadow: {
      xs: "none",
      md: "4px 8px 11px 0px rgb(0 0 0 / 25%)"
    },
    p: 10,
    backgroundColor: "white",
    borderRadius: "semi",
    height: "max-content"
  }, children));
};