import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { noop } from "@catchoftheday/utilities";
var defaultCustomerParams = {
  isLoggedIn: true,
  onePass: {
    club: {
      active: false,
      freeTrialEligible: true,
      onFreeTrial: false
    },
    membership: {
      id: "1",
      linked: true,
      doubleSubscription: false,
      eligibleForMigration: false,
      subscription: {
        active: false,
        activeDaysRemaining: 20,
        autoRenewing: true,
        interval: "MONTHLY",
        latestMembershipPeriodEnd: "2023-06-05T23:59:59.000Z",
        freeTrialEligible: false,
        freeTrialDaysRemaining: 30,
        onFreeTrial: false,
        purchasePrice: 400,
        isTeamMember: false
      }
    }
  },
  flybuys: {
    isLinked: false,
    maskedCardNumber: null
  }
};
export var buildMockCustomer = function buildMockCustomer() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultCustomerParams,
      _ref$isLoggedIn = _ref.isLoggedIn,
      isLoggedIn = _ref$isLoggedIn === void 0 ? true : _ref$isLoggedIn,
      _ref$onePass = _ref.onePass,
      club = _ref$onePass.club,
      membership = _ref$onePass.membership,
      flybuys = _ref.flybuys;

  if (!isLoggedIn) {
    return null;
  }

  return {
    __typename: "Customer",
    id: "1234",
    displayName: "Joe",
    email: "joe@catch.com.au",
    club: club !== null ? _objectSpread(_objectSpread({
      __typename: "ClubCustomerSubscription"
    }, defaultCustomerParams.onePass.club), club) : null,
    membership: membership !== null ? _objectSpread(_objectSpread(_objectSpread({}, defaultCustomerParams.onePass.membership), membership), {}, {
      subscription: _objectSpread(_objectSpread({}, defaultCustomerParams.onePass.membership.subscription), membership.subscription)
    }) : null,
    flybuys: flybuys !== null ? _objectSpread(_objectSpread({}, defaultCustomerParams.flybuys), flybuys) : null,
    storeCredit: {
      credit: {
        amount: 20
      },
      expiry: "2024-05-18T13:59:59.000Z"
    },
    metaData: {
      customerUuid: "e2e51708-588c-41da-8e2a-b8b2de8ac62e"
    },
    dob: "1990-01-01",
    gender: "m"
  };
};
export var mockCustomer = buildMockCustomer();
export var mockCustomerContext = function mockCustomerContext(contextData) {
  return _objectSpread({
    data: mockCustomer,
    loading: false,
    loggedIn: true,
    refetchCustomer: noop,
    logIn: noop
  }, contextData);
};