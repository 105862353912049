/**
 * Styles for the different button sizes
 */
import { variant } from "@catchoftheday/theme";
var sizeVariantDefs = {
  xs: {
    fontSize: "xs",
    padding: "0.5rem 0.75rem",
    borderRadius: "default",
    fontWeight: "normal"
  },
  sm: {
    fontSize: "sm",
    padding: "0.75rem 1rem",
    borderRadius: "default",
    fontWeight: "normal"
  },
  md: {
    fontSize: "base",
    padding: "0.9rem",
    borderRadius: "default",
    fontWeight: "normal",
    minWidth: ["auto", "7.5rem"]
  },
  lg: {
    fontSize: "xl",
    padding: "0.95rem 1.25rem",
    borderRadius: "default",
    fontWeight: "normal",
    minWidth: ["auto", "10rem"]
  },
  xl: {
    fontSize: "2xl",
    padding: "1rem 1.25rem",
    borderRadius: "default",
    fontWeight: "normal",
    minWidth: ["auto", "12.5rem"]
  }
};
export var sizeVariant = variant({
  prop: "sizeVariant",
  variants: sizeVariantDefs
});