import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["isError"];
import React from "react";
import { Span, toast } from "@catchoftheday/cg-components";
import { HEADER_TOAST_CONTAINER_ID } from "./consts.js";
import { jsx as ___EmotionJSX } from "@emotion/react";

var Message = function Message(_ref) {
  var isError = _ref.isError,
      props = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(Span, _extends({
    color: isError ? "error" : "primary",
    m: 0,
    fontWeight: "medium"
  }, props));
};

export var triggerToast = function triggerToast(_ref2) {
  var isError = _ref2.isError,
      message = _ref2.message;
  toast(___EmotionJSX(Message, {
    isError: isError
  }, message), {
    containerId: HEADER_TOAST_CONTAINER_ID,
    autoClose: 5000,
    position: "top-center"
  });
};