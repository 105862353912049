import { ONEPASS_ACTION_LINKING_EXIST_MODAL, ONEPASS_ACTION_LINKING_MODAL, ONEPASS_ACTION_UPSELL_MODAL, ONEPASS_ACTION_UPSELL_MODAL_HEADER } from "@analytics/consts"; // Cancellation Modal

// Linking Modal
var LinkingModalActionConst = {
  linking: ONEPASS_ACTION_LINKING_MODAL,
  linkingExist: ONEPASS_ACTION_LINKING_EXIST_MODAL
};
// Upsell Modal
var UpsellModalActionConst = {
  modal: ONEPASS_ACTION_UPSELL_MODAL,
  headerPopup: ONEPASS_ACTION_UPSELL_MODAL_HEADER
};