var _process$env$NEXT_PUB;

/**
 * Embed Config values are provided at build time by the CI/CD pipeline. They are
 * embedded into the app at build time so are NOT immutable.
 *
 * ⚠️ Only values which can be used across multiple environments can be added here.
 *
 * 🚨 Variables on `process.env` must be prefixed with `NEXT_PUBLIC_` to be embedded into the app.
 *
 * 🔧 All variables from `process.env` need to be added to `~/nx.json`
 * `targetDefaults.nx:compile-app.inputs` to ensure changes are picked up by the build.
 */

/* eslint-disable n/no-process-env */
export var embeddedConfig = {
  /** App Name */
  APP_NAME: process.env.NEXT_PUBLIC_APP_NAME,

  /** The build version. */
  VERSION: (_process$env$NEXT_PUB = process.env.NEXT_PUBLIC_VERSION) !== null && _process$env$NEXT_PUB !== void 0 ? _process$env$NEXT_PUB : process.env.CI ? undefined : "local"
};