import React from "react";
import { useGetAnalyticsConfigurationQuery } from "@analytics/generated/graphql";
import { AppsFlyer } from "../vendors/appsFlyer";
import { GTM } from "../vendors/gtm";
import { SnowplowContextProvider } from "../vendors/snowplow/SnowplowContext";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var AnalyticsVendors = function AnalyticsVendors(_ref) {
  var host = _ref.host,
      children = _ref.children;

  var _useGetAnalyticsConfi = useGetAnalyticsConfigurationQuery({
    variables: {
      domain: host
    }
  }),
      data = _useGetAnalyticsConfi.data;

  var analytics = data === null || data === void 0 ? void 0 : data.cmsConfiguration.analytics;

  if (!analytics) {
    return null;
  }

  var snowplowConfig = analytics.snowplow;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(GTM, analytics === null || analytics === void 0 ? void 0 : analytics.gtm), ___EmotionJSX(AppsFlyer, null), ___EmotionJSX(SnowplowContextProvider, {
    config: snowplowConfig
  }, children));
};