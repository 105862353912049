import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2;

import { LazyLoadImage } from "react-lazy-load-image-component";
import { compose, layout, position, space, styled } from "@catchoftheday/theme";
var styleProps = compose(space, layout, position);
var style = "\n  display: block;\n  width: 100%;\n  height: auto;\n"; // represents basic HTML img tag

export var Image = styled.img(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n  ", "\n"])), style, styleProps); // lazy loaded image

export var ImageLazy = styled(LazyLoadImage)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n"])), style, styleProps);
export default Image;