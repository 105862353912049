import { Events } from "../types";
import { submitSPEvents } from "./emitSnowplowEvent";
export function emitSalesRuleTagImpression(_ref) {
  var sales_rule_id = _ref.sales_rule_id,
      source_id = _ref.source_id,
      label = _ref.label,
      destination_url = _ref.destination_url;
  submitSPEvents([{
    context: Events.SALES_RULE_TAG_IMPRESSION,
    options: {
      sales_rule_id: sales_rule_id,
      source_id: source_id,
      label: label,
      destination_url: destination_url
    }
  }]);
}