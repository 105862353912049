import { useCallback, useContext, useEffect, useState } from "react";
import { SnowplowContext } from "../../vendors/snowplow/SnowplowContext";
export var useTriggerImpression = function useTriggerImpression(_ref) {
  var emitFn = _ref.emitFn,
      _ref$inView = _ref.inView,
      inView = _ref$inView === void 0 ? false : _ref$inView,
      _ref$triggerOnce = _ref.triggerOnce,
      triggerOnce = _ref$triggerOnce === void 0 ? true : _ref$triggerOnce,
      _ref$isMock = _ref.isMock,
      isMock = _ref$isMock === void 0 ? false : _ref$isMock;

  var _useState = useState(false),
      isTriggered = _useState[0],
      setIsTriggered = _useState[1];

  var _useContext = useContext(SnowplowContext),
      initialized = _useContext.initialized;

  var isInitialized = initialized || isMock;
  var shouldTrigger = triggerOnce && !isTriggered || !triggerOnce;
  var triggerImpression = useCallback(function () {
    if (isInitialized && inView && shouldTrigger && typeof emitFn === "function") {
      emitFn();
      setIsTriggered(true);
    }
  }, [emitFn, inView, isInitialized, shouldTrigger]);
  useEffect(function () {
    if (isInitialized) {
      triggerImpression();
    }
  }, [isInitialized, triggerImpression]);
  return {
    isTriggered: isTriggered,
    triggerImpression: triggerImpression
  };
};