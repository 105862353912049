import React, { useContext } from "react";
import { HtmlContext } from "next/dist/shared/lib/html-context.js";
import { getEnvConfig } from "../public/get.js";
/**
 * Wraps a Next document with HtmlContext with assetPrefix set in the runtime
 * Reference: https://github.com/vercel/next.js/discussions/38142#discussioncomment-3128588
 */

import { jsx as ___EmotionJSX } from "@emotion/react";
export function NextEnvContext(_ref) {
  var setAssetPrefix = _ref.setAssetPrefix,
      children = _ref.children;
  var staticUrl = getEnvConfig().STATIC_URL; // This is a workaround to ensure that the assetPrefix is available in the runtime
  // -and is accessible via __NEXT_DATA__.assetPrefix, which is needed for any preloads and prefetches of assets.

  setAssetPrefix(staticUrl);
  var context = useContext(HtmlContext);
  if (context) context.assetPrefix = staticUrl;
  return ___EmotionJSX(HtmlContext.Provider, {
    value: context
  }, children);
}