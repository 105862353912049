import { useCallback, useEffect, useRef, useState } from "react"; // eslint-disable-next-line @typescript-eslint/no-empty-function

export var noop = function noop() {};
export function useControlled(
/** Default value when uncontrolled */
defaultValueProps,
/** Current controlled value */
valueProps,
/** Called whenever the value changes */
changeHandler) {
  // value was given by consumer means consumer want to take over value control;
  var isControlled = valueProps !== undefined;
  /** don't allow switch control mode between each render since it will make state messy */

  var initialIsControlledRef = useRef(isControlled);
  useEffect(function () {
    if (initialIsControlledRef.current !== isControlled) {
      console.warn("can not switch the control mode between each render");
    }
  }, [isControlled]);

  var _useState = useState(defaultValueProps),
      uncontrolledValue = _useState[0],
      setUncontrolledValue = _useState[1];

  var value = valueProps !== undefined ? valueProps : uncontrolledValue;
  var setValue = useCallback(function (val) {
    if (!isControlled) {
      setUncontrolledValue(val);
    } // Type cast to deal with strange error with "T & Function is not callable"


    changeHandler === null || changeHandler === void 0 ? void 0 : changeHandler(typeof val === "function" ? val(value) : val);
  }, [changeHandler, value, isControlled]);
  /** Return value mimics React.useState */

  return [value, setValue];
}