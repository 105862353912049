import React from "react";
import { AppExperimentProvider } from "@catchoftheday/analytics";
import { CustomerContextProvider } from "@catchoftheday/customer";
import { getEnvConfig, withEnvConfig } from "@catchoftheday/env-config";
import { withApollo } from "@catchoftheday/graphql-utils";
import { MEGA_MENU_GRAPHQL_OPERATION_NAME } from "../const";
import { jsx as ___EmotionJSX } from "@emotion/react";

function HeaderApp(_ref) {
  var Component = _ref.Component,
      pageProps = _ref.pageProps;
  return ___EmotionJSX(CustomerContextProvider, null, ___EmotionJSX(AppExperimentProvider, null, ___EmotionJSX(Component, pageProps)));
}

export default withEnvConfig(withApollo({
  uri: function uri() {
    return getEnvConfig().GRAPHQL_ENDPOINT;
  },
  retryOptions: {
    attempts: {
      // Retry the Mega Menu query up to 5 times as we no longer have a fallback menu.
      max: 5,
      retryIf: function retryIf(_error, operation) {
        return operation.operationName === MEGA_MENU_GRAPHQL_OPERATION_NAME;
      }
    },
    retryTimeout: 1500
  }
})(HeaderApp));