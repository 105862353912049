import currency from "currency.js";
export var formatPrice = function formatPrice(price) {
  var integerDecimals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var roundDownToNearestDollar = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var formatPrice = roundDownToNearestDollar && price >= 100 ? Math.floor(price / 100) : price / 100;
  var isIntegerPrice = formatPrice % 1 === 0;
  return currency(formatPrice, {
    precision: !integerDecimals && isIntegerPrice ? 0 : 2
  }).format(true);
};
export var formatPriceWithFreeText = function formatPriceWithFreeText(price) {
  var freeText = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "Free";
  return price === 0 ? freeText : formatPrice(price);
};
export var centsToDollars = function centsToDollars(price) {
  return price / 100;
};
export var dollarsToCents = function dollarsToCents(dollars) {
  return dollars * 100;
};