/**
 * 💅 The purpose of this util function is to cater for Emotion's shouldForwardProp.
 * This function itself is mostly similar to @styled-system/should-forward-prop,
 * in fact it's a copy of it at first, but with an additional logic
 * to let us bypass @emotion/is-prop-valid check.
 */
import isPropValid from "@emotion/is-prop-valid";
import memoize from "@emotion/memoize";
import { background, border, buttonStyle, color, colorStyle, compose, flexbox, grid, layout, position, shadow, space, textStyle, typography } from "styled-system";
var all = compose(space, typography, color, layout, flexbox, border, background, position, grid, shadow, buttonStyle, textStyle, colorStyle);
export var styledSystemPropNames = all.propNames;
/**
 *
 * Here we have the same `createShouldForwardProp` function from @styled-system/should-forward-prop
 * with an additional `allowedProps` argument to allow us to pass down custom props.
 * This is useful when we want to pass down props to the underlying DOM element that is not
 * a valid HTML attributes (filtered by @emotion/is-prop-valid) in cases where we want to style
 * custom built components with emotion and styled-system.
 * @param bannedProps - Array of strings of props to be filtered out by shouldForwardProp function
 * @param allowedProps - Array of strings of props to be allowed/bypassed by shouldForwardProp function
 * @returns styled's shouldForwardProp function
 */

export var createShouldForwardProp = function createShouldForwardProp() {
  var bannedProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var allowedProps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var regexBanned = new RegExp("^(".concat(bannedProps.join("|"), ")$"));
  var regexAllowed = new RegExp("^(".concat(allowedProps.join("|"), ")$"));
  return memoize(function (prop) {
    return (isPropValid(prop) || regexAllowed.test(prop)) && !regexBanned.test(prop);
  });
};
export var createDefaultShouldForwardProp = function createDefaultShouldForwardProp(allowedProps) {
  return createShouldForwardProp(styledSystemPropNames, allowedProps);
};