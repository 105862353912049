import { trackStructEvent } from "@snowplow/browser-tracker";
import { DELIVERY_ESTIMATE_IMPRESSION } from "../consts";
export function emitDeliveryEstimateImpression(_ref) {
  var label = _ref.label,
      property = _ref.property,
      value = _ref.value;
  trackStructEvent({
    label: label,
    property: property,
    action: "",
    value: value,
    category: DELIVERY_ESTIMATE_IMPRESSION
  });
}