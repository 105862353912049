import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";

var _templateObject;

import { createShouldForwardProp, props } from "@styled-system/should-forward-prop";
import { background, border, color, compose, cursorProp, flexbox, float as _float, grid, gridReplacementProps, layout, pointerEvents, position, shadow, space, styled, textTransformProp, typography, visibilityProp } from "@catchoftheday/theme";
export var boxStyleProps = compose(visibilityProp, cursorProp, space, layout, background, position, color, typography, shadow, border, flexbox, grid, textTransformProp, _float, gridReplacementProps, pointerEvents);
var shouldForwardProp = createShouldForwardProp([].concat(_toConsumableArray(props), ["pointerEvents"]));
export var Box = styled("div", {
  shouldForwardProp: shouldForwardProp
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), boxStyleProps);
Box.displayName = "Box";
export default Box;