import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;

/**
 * Styles for the different types of button variant/styles.
 */
import get from "lodash.get";
import { css, focusOutlineProp, hoverBorderColorProp, themeGet, variant } from "@catchoftheday/theme";

var getColorVariant = function getColorVariant(props) {
  var suffix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  return get(props.theme, "colors.button.".concat(props.variant).concat(suffix));
};
/**
 * Styles that apply to all buttons.
 */


function allStyles(props) {
  return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    ", "\n\n    margin: 0;\n    line-height: ", ";\n    text-align: center;\n    text-decoration: none;\n    border: ", ";\n    border-color: transparent;\n    border-radius: ", ";\n    outline: 0;\n    transition: background-color ", ",\n      border-color ", ";\n    cursor: pointer;\n    appearance: none;\n\n    background-color: ", ";\n    color: ", ";\n\n    &:hover:not(:disabled):not([disabled]) {\n      background-color: ", ";\n      color: ", ";\n      ", "\n      ", "\n    }\n\n    &:focus[data-focus-visible-added],\n    &:focus-visible {\n      background-color: ", ";\n    }\n\n    &:disabled {\n      background-color: ", ";\n      color: ", ";\n      opacity: ", ";\n      cursor: not-allowed;\n    }\n\n    &:active {\n      box-shadow: ", ";\n    }\n  "])), props.block ? css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n          width: 100%;\n        "]))) : "", themeGet("lineHeights.normal")(props), themeGet("borders.2")(props), themeGet("radii.default")(props), themeGet("transitions.default")(props), themeGet("transitions.default")(props), getColorVariant(props), getColorVariant(props, "Text"), getColorVariant(props, "Hover"), getColorVariant(props, "HoverText"), props.underlineOnHover && "text-decoration: underline;", hoverBorderColorProp(props), getColorVariant(props, "Hover"), getColorVariant(props, "Disabled"), getColorVariant(props, "DisabledText"), themeGet("opacity.disabled")(props), themeGet("shadows.button")(props));
}

function getStyleVariant(props) {
  if (props.variant === "plain") {
    return "plain";
  }

  if (props.outline) {
    return "outlined";
  }

  return "contained";
}
/**
 * Style by the overall type of button eg outlined vs plain vs contained
 */


function categoryStyles(props) {
  var realVariant = getStyleVariant(props);

  switch (realVariant) {
    case "outlined":
      {
        return css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n        ", ";\n\n        border-color: ", ";\n        background-color: white;\n\n        &:hover:not(:disabled):not([disabled]),\n        &:focus[data-focus-visible-added],\n        &:focus-visible {\n          color: white;\n          border-color: ", ";\n        }\n\n        &:disabled {\n          color: ", ";\n          border-color: ", ";\n          background-color: transparent;\n        }\n      "])), !props.color && "color: ".concat(getColorVariant(props)), getColorVariant(props), getColorVariant(props, "Hover"), themeGet("colors.typography.inactive")(props), themeGet("colors.typography.inactive")(props));
      }

    case "plain":
      {
        return css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n        &:active {\n          box-shadow: none;\n        }\n\n        &:focus[data-focus-visible-added],\n        &:focus-visible {\n          ", ";\n        }\n      "])), focusOutlineProp(props));
      }

    case "contained":
      {
        return css(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral([""])));
      }
  }
}
/**
 * Styles for button edges (rounded or not)
 */


function edgesStyles(props) {
  if (props.rounded) {
    return css(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n      border-radius: ", " !important;\n    "])), themeGet("radii.roundedButton")(props));
  }

  return css(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral([""])));
}
/**
 * Styles that apply to specific named variants such as primary/secondary/onePass
 */


var buttonVariantDefs = {
  primary: {// You can pass further styling customisation as per below
    // color: "white",
    // bg: "primary",
    // "&:active": {
    //   boxShadow: "button",
    // },
  },
  secondary: {},
  tertiary: {},
  error: {},
  plain: {},
  onePassPrimary: {},
  onePassOutline: {},
  onePassSecondary: {}
};
var specificStyles = variant({
  variants: buttonVariantDefs
});
/**
 * All variant styles. More specific styles win
 */

export function styleVariant(props) {
  return css(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    ", "\n    ", "\n    ", "\n    ", "\n  "])), allStyles(props), categoryStyles(props), edgesStyles(props), specificStyles(props));
}