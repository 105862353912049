import { differenceInCalendarDays, differenceInHours, differenceInMinutes, differenceInSeconds } from "date-fns";
import enAU from "date-fns/locale/en-AU/index.js";
import { formatInTimeZone } from "date-fns-tz";
export var format = function format(date) {
  var formatString = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "dd MM yyyy";

  try {
    return formatInTimeZone(typeof date === "string" ? new Date(date) : date, "Australia/Melbourne", formatString, {
      locale: enAU
    });
  } catch (_) {
    return "n/a";
  }
};
/**
 * Extension usage of date-fns' differenceIn...
 * formatted in the following order:
 *
 * - seconds
 * - minutes
 * - hours
 * - days
 */

export var formatDifference = function formatDifference(dateLeft, dateRight) {
  try {
    var firstDate = typeof dateLeft === "string" ? new Date(dateLeft) : dateLeft;
    var secondDate = typeof dateRight === "string" ? new Date(dateRight) : dateRight;
    var diffInSeconds = differenceInSeconds(firstDate, secondDate);
    var absDiffInSeconds = Math.abs(diffInSeconds);

    if (absDiffInSeconds < 60) {
      return {
        value: diffInSeconds,
        unit: "second"
      };
    }

    if (absDiffInSeconds < 3600) {
      return {
        value: differenceInMinutes(firstDate, secondDate),
        unit: "minute"
      };
    }

    if (absDiffInSeconds < 86400) {
      return {
        value: differenceInHours(firstDate, secondDate),
        unit: "hour"
      };
    }

    return {
      value: differenceInCalendarDays(firstDate, secondDate),
      unit: "day"
    };
  } catch (_) {
    return "n/a";
  }
};