import React from "react";
import { Box, Heading, Image, Link, LinkButton, Text } from "@catchoftheday/cg-components";
import { CATCH_LOGO_URL, CUSTOMER_SUPPORT_URL, TERMS_AND_CONDITIONS_URL } from "../../consts";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var AccountBlocked = function AccountBlocked() {
  return ___EmotionJSX(Box, null, ___EmotionJSX(Image, {
    width: "120px",
    height: "52px",
    alt: "Catch Logo",
    src: CATCH_LOGO_URL,
    margin: "0 auto"
  }), ___EmotionJSX(Heading.h2, null, "Your account has been blocked"), ___EmotionJSX(Text, {
    fontWeight: "normal",
    mt: 0,
    mb: 6
  }, "We're sorry, but your account has been blocked due to multiple unsuccessful login attempts or a violation of our", " ", ___EmotionJSX(Link, {
    href: TERMS_AND_CONDITIONS_URL
  }, "Terms & Conditions"), ". Please contact customer support for further assistance."), ___EmotionJSX(LinkButton, {
    variant: "plain",
    outline: true,
    borderColor: "typography.text",
    borderWidth: "1px",
    href: CUSTOMER_SUPPORT_URL,
    mb: 4
  }, "Contact customer support"), ___EmotionJSX(LinkButton, {
    href: "/logout",
    variant: "secondary"
  }, "Continue as guest"));
};