import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

/**
 * Delivery Estimates Impression Analytics
 */
import { useIsInViewport } from "@catchoftheday/utilities";
import { emitDeliveryEstimateImpression } from "../../events";

/**
 * Emits a delivery estimates impression event when the returned `ref` enters
 * the viewport.
 *
 * @example
 *
 */
export function useDeliveryEstimatesImpressionAnalytics(_ref) {
  var _ref$enabled = _ref.enabled,
      enabled = _ref$enabled === void 0 ? false : _ref$enabled,
      context = _ref.context,
      options = _ref.options;

  // Emit the event when the ref enters the viewport.
  var handleComponentSeen = function handleComponentSeen() {
    // Provide some DX by warning the developer if they've enabled analytics but
    // not provided any context.
    // eslint-disable-next-line n/no-process-env
    if (false) {
      if (enabled && !context) {
        console.warn("You've enabled delivery estimate impression analytics but not provided any context.");
      }
    } // Do nothing if not enabled or missing options.


    if (!enabled || !context) return;
    var property = context.offerId,
        postcode = context.postcode,
        label = context.labelType;
    if (!property || !postcode) return; // Do nothing if missing required context values.
    // Convert postcode to a number.

    var value = Number(postcode); // TODO: Postcodes can't really be numbers (N.T. is 0800, for example). However the underlying `trackStructEvent` requires `value` to be a number.
    // Alrighty, let's emit the event.

    emitDeliveryEstimateImpression({
      label: label,
      property: property,
      value: value
    });
  };

  var _useIsInViewport = useIsInViewport(_objectSpread({
    shouldTrackVisibility: enabled,
    onComponentSeen: handleComponentSeen
  }, options)),
      ref = _useIsInViewport.ref;

  return {
    /** Apply `ref` to the element containing the delivery estimate label. */
    ref: ref
  };
}